<template>
    <v-dialog v-model="dialog" width="500" no-click-animation persistent>
        <v-card>
            <v-card-title>Stop Service</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <v-layout column>
                    <v-flex md12 v-if="!isTransfer">
                        Are you sure you want to stop the FTPS Server?
                    </v-flex>
                    <v-flex md12 v-else>
                        Data is being transferred. Are you sure you want to stop the FTPS service?
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small class="ml-3 text-none" color="blue darken-1" flat @click="close">Cancel</v-btn>
                <v-btn small class="text-none" color="primary" :loading="loading" @click="ok">Confirm</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import dialogMixin from '@/module/mixins/loading.mixin'
    import {mapState} from 'vuex'

    export default {
        name: 'DelFtp',
        mixins: [dialogMixin],
        data() {
            return {
                isTransfer: false
            }
        },
        computed: {
            ...mapState('application', ['appInfo']),
        },
        methods: {
            open() {
                return this.$http.get(`/package_assist_services/${this.appInfo.ftp_service.id}/has_data_transfer`).then((res) => {
                    this.isTransfer = res.has_data_transfer
                    this.dialog = true
                    this.$listeners.confirm(false)
                    return Promise.resolve(res)
                }).catch((e) => {
                    this.$message.error(e.detail)
                    this.$listeners.confirm(false)
                    return Promise.reject(e)
                })
            },
            close() {
                if (!this.loading) {
                    this.dialog = false
                     this.$listeners.confirm(false)
                }
            },
            ok() {
                let done = () => {
                    this.loading = false;
                    this.dialog = false
                }
                this.loading = true;
                let p = this.$listeners.confirm(true)
                if (p) {
                    p.then(() => {
                        done()
                    }).catch(() => {
                        this.loading = false;
                    })
                } else {
                    done()
                }
            }
        },
    }
</script>