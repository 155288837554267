<template>
    <v-container class="panel-wrapper panel-overview pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="FTPS Server">
                    <template v-if="ready">
                        <template v-if="ftpStatus!=='Failed'">
                            <v-layout row >
                                <v-flex v-if="ftpAppInfo.length">
                                    <PropertyTable text-key="title" text-value="content" :items="ftpAppInfo"></PropertyTable>
                                    <div class="text-xs-left" style="padding: 0;">
                                        <v-btn color="primary" class="mx-0 my-3 text-none" @click="loading=true,$refs.delftp.open()"
                                               :loading="loading"
                                               :disabled="loading">Stop
                                        </v-btn>
                                    </div>
                                </v-flex>
                                <v-flex v-else>
                                    <div class="text-xs-left" style="padding: 30px 0px 0px;">
                                        <v-btn :title="`Start the FTPS server so you can start uploading files.`" color="primary" class="ma-0 text-none" @click="startService"
                                               :loading="isInstalling || loading" :disabled="isInstalling || loading">Start
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </template>
                        <template v-else>
                            <v-layout >
                                <v-flex>
                                    <p class="fs-3 font-weight-regular" style="color: rgb(102, 102, 102); ">
                                        The FTPS server installation failed, please try again later or submit a ticket to us.
                                    </p>
                                    <div class="text-xs-right" style="padding: 30px 0px 0px;">
                                        <v-btn color="primary" class="mx-2 text-none" @click="$refs.ticket.open()" dense> Open New Ticket
                                        </v-btn>
                                        <v-btn color="primary" class="ma-0 text-none" @click="reintallService" :loading="loading"
                                               :disabled="loading">Reinstall
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </template>
                    </template>
                    <template v-else>
                        <fy-vue-skeleton
                                animation="fade"
                                :rowOptions="{padding : '10px'}"
                                :childOptions="skeletonOptions"
                        ></fy-vue-skeleton>
                    </template>
                    <Help :mod="['menu_tab_ftps']" :product="appInfo.product_name"></Help>
                </TabCard>
            </v-flex>
        </v-layout>
        <DelFtp ref="delftp" @confirm="delService"></DelFtp>
        <Confirm ref="ticket" maxWidth="500px" @confirm="openTicket">
            <template v-slot:title="{options}">
                <span>
                    Open New Ticket
                </span>
            </template>
            <template v-slot:content="{options}">
                <p>
                    Please confirm and click Send in the next ticket panel. Our support staff will respond to you in 1-2
                    hours.
                </p>
            </template>
        </Confirm>
    </v-container>
</template>

<script>
    import loadingMixin from '@/module/mixins/loading.mixin.js'
    import Help from '@/components/Help'
    import PropertyTable from "@/components/PropertyTable";
    import {formatDate} from '@/module/utils/date'
    import DelFtp from "./_source/DelFtp"
    import Confirm from '@/components/Confirm.vue'
    import TabCard from '@/components/TabCard.vue'
    import {mapState, mapActions} from 'vuex'

    const skeletonOptions = [
        {type: 'repeat', list: [
        { span: 24, type: 'card', options: { height: '150px', width:'100%' } },
        { span: 24, type: 'list', options: { repeat:2,list:[{ height: '20px', width:'100%',marginTop:'10px'}] } },
        { span: 24, type: 'card', justify: 'flex-end', align: 'flex-end', options: { height: '30px', width:'10%' ,marginTop:'10px'} }
        ]}
    ]

    let interver = 5000
    export default {
        name: 'FtpServer',
        mixins: [loadingMixin],
        data() {
            return {
                ready : false,
                loading: false,
                timer: null,
                ftpStatus: '',
                ftpId : ''
            }
        },
        components: {PropertyTable, TabCard, DelFtp, Confirm ,Help},
        computed: {
            ftp_service() {
                return this.appInfo.ftp_service
            },
            ftpAppInfo() {
                return this.ftp_service.id&&this.ftp_service.status==='Active'?[
                    {title: 'FTP host server', content: this.ftp_service.cloud_service_ip_instance.domain_name ? this.ftp_service.cloud_service_ip_instance.domain_name : '--' },
                    {title: 'FTP username', content: this.ftp_service.data.ftpUser},
                    {
                        title: 'Created',
                        content: this.ftp_service.created ? formatDate(this.ftp_service.created, 'MM/DD/YYYY HH:mm') : '--'
                    },
                    {
                        title: 'FTP port',
                        content: typeof (this.ftp_service.ports) === 'object' ? this.ftp_service.ports[0] : this.ftp_service.ports
                    },
                    {title: 'Password', content: this.ftp_service.data.ftpPass},

                    {
                        title: 'Expired at',
                        content: this.ftp_service.expired_time ? formatDate(this.ftp_service.expired_time, 'MM/DD/YYYY HH:mm') : '--'
                    },

                ] : []
            },
            isInstalling() {
                return this.ftpStatus === 'Installing'
            },
            ...mapState('application', ['appInfo']),
        },
        methods: {
            formatDate,
            getFtpStatus() {
                return this.$http.get(`/package_assist_services/${this.ftpId}/get_service_status/`)
            },
            //删除ftp服务
            delService(flag) {
                if(flag){
                    return this.$http.delete(`package_assist_services/${this.ftpId}/`).then(() => {
                        this.tick()
                    }).catch((e) => {
                        if (this.ftp_service.status !== 'Failed') {
                            this.$message.error(e.detail)
                        }
                        clearTimeout(this.timer)
                        this.loading = false
                    })
                }else{
                    this.loading = false
                    return Promise.resolve()
                }
            },
            // 启动ftp服务
            startService() {
                this.loading = true
                this.$http.post('/package_assist_services/', {
                    name: 'ftps',
                    package: this.appInfo.id
                }).then((res) => {
                    this.ftpId = res.id
                    this.ftpStatus  = res.status
                    this.tick()
                }).catch((err) => {
                    this.$message.error(err.detail)
                    this.loading = false
                })
            },
            openTicket() {
                return Promise.resolve(this.$router.push({
                    path: '/ticket',
                    query: {
                        status: 'FtpFailed',
                        name: this.appInfo.name
                    },
                }))
            },
            reintallService() {
                this.loading = true
                return this.$http.post(`/package_assist_services/${this.appInfo.ftp_service.id}/reinstall/`).then(({status}) => {
                    if(status === 'Failed') {
                        this.ftpStatus  = status
                    } else{
                        this.tick()
                    }

                }).catch(e => {
                    this.loading = false
                    this.$message.error(e.detail)
                })
            },

            fetchFtpStatus(){
                return new Promise((resolve, reject) => {
                    this.getFtpStatus().then(({status}) => {
                        if (!status || status === 'Active') {
                            this.loading = false
                            clearTimeout(this.timer)
                            resolve(status)
                        }  else {
                            this.timer = setTimeout(() => {
                                this.fetchFtpStatus().then(st => {
                                    resolve(st)
                                })
                            },interver)
                        }
                    }).catch(()=> {
                        this.loading = false
                        reject('Failed')
                    })
                })
            },
            tick() {
                this.loading = true
                this.fetchFtpStatus().then((status) => {
                    this.ftpStatus  = status
                    if(!status){
                        this.ftpId = ''
                    }
                    this.getAppDetailById({appId: this.appId})
                }).catch(st => {
                    this.ftpStatus  = st
                })
            },
            ...mapActions('application', ['getAppDetailById'])
        },
        created() {
            this.skeletonOptions = skeletonOptions
            this.appId = this.$route.params.appId
            this.getAppDetailById({appId: this.appId}).then(() => {
                this.ready = true
                this.ftpStatus  = this.ftp_service.status
                this.ftpId  = this.ftp_service.id
                if (this.ftp_service.status === 'Installing' || this.ftp_service.status === 'Deleting') {
                    this.loading = true;
                    this.tick()
                }
            }).catch(e => {
                this.$message.error(e.detail)
            })
            this.$SDK.track({pageName:'FTPS Server',productName:this.appInfo.product_name})
        },
        beforeDestroy() {
            clearTimeout(this.timer)

        }
    }
</script>
<style lang="scss">
    .panel-ftp {
        .source-table {
            thead, tbody {
                tr {
                    border-bottom: 0px solid transparent !important;
                }
            }
        }

    }

</style>