<template>
    <table class="property-table" border="0">
        <template v-for="(list, index) in lists">
            <tr class="tr-t" :key="index">
                <td v-for=" (val,i) in list[0]" :key="i" :width="100/cols+'%'" class="font-weight-bold fs-4" v-html="val"></td>
            </tr>
            <tr class="tr-b" :key="index">
                <td v-for=" (val,i) in list[1]" :key="i" class="fs-4" v-html="val"></td>
            </tr>
        </template>
    </table>
</template>

<script>
    export default {
        name: "PropertyTable",
        props : {
            items:{
                type: Array,
                require : true,
                default(){
                    return []
                }
            },
            textKey: {
                type: String
            },
            textValue: {
                type : String
            },
            cols:{
                type: Number,
                default: 3
            }
        },
        computed: {
            lists(){
                let length = this.items.length
                let arr = []
                if(this.items.length){
                    let ept = length%this.cols?this.cols-length%this.cols:0
                    for(let i = 0; i < length ; i++){
                        if(i%this.cols === 0){
                            arr.push([[],[]])
                        }
                        let item = this.items[i]
                        arr[arr.length-1][0].push(item[this.textKey])
                        arr[arr.length-1][1].push(item[this.textValue])
                        if(i === length-1){
                            for(let k = 0;k <ept ;k++){
                                arr[arr.length-1][0].push('')
                                arr[arr.length-1][1].push('')
                            }
                        }
                    }
                }
                return  arr
            }
        },
    }
</script>

<style lang="scss">
    .property-table{
        width: 100%;
        border: 1px solid  #ebebeb ;
        border-collapse: collapse;
        tr{
            border-color: inherit;
        }
        td{
            border-right: 1px solid #f3f3f3;
            padding: 8px;
        }
        tr.tr-t{
            td{
                background-color: #ebebeb;
            }
        }
        tr.tr-b{
            td{
                background-color: #fff;
                color: #666;
            }
        }
    }
</style>
