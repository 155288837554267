var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-overview pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "FTPS Server" } },
                [
                  _vm.ready
                    ? [
                        _vm.ftpStatus !== "Failed"
                          ? [
                              _c(
                                "v-layout",
                                { attrs: { row: "" } },
                                [
                                  _vm.ftpAppInfo.length
                                    ? _c(
                                        "v-flex",
                                        [
                                          _c("PropertyTable", {
                                            attrs: {
                                              "text-key": "title",
                                              "text-value": "content",
                                              items: _vm.ftpAppInfo
                                            }
                                          }),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-xs-left",
                                              staticStyle: { padding: "0" }
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-0 my-3 text-none",
                                                  attrs: {
                                                    color: "primary",
                                                    loading: _vm.loading,
                                                    disabled: _vm.loading
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      ;(_vm.loading = true),
                                                        _vm.$refs.delftp.open()
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "Stop\n                                    "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c("v-flex", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-xs-left",
                                            staticStyle: {
                                              padding: "30px 0px 0px"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "ma-0 text-none",
                                                attrs: {
                                                  title:
                                                    "Start the FTPS server so you can start uploading files.",
                                                  color: "primary",
                                                  loading:
                                                    _vm.isInstalling ||
                                                    _vm.loading,
                                                  disabled:
                                                    _vm.isInstalling ||
                                                    _vm.loading
                                                },
                                                on: { click: _vm.startService }
                                              },
                                              [
                                                _vm._v(
                                                  "Start\n                                    "
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                ],
                                1
                              )
                            ]
                          : [
                              _c(
                                "v-layout",
                                [
                                  _c("v-flex", [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "fs-3 font-weight-regular",
                                        staticStyle: {
                                          color: "rgb(102, 102, 102)"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    The FTPS server installation failed, please try again later or submit a ticket to us.\n                                "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-xs-right",
                                        staticStyle: { padding: "30px 0px 0px" }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mx-2 text-none",
                                            attrs: {
                                              color: "primary",
                                              dense: ""
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.$refs.ticket.open()
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Open New Ticket\n                                    "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "ma-0 text-none",
                                            attrs: {
                                              color: "primary",
                                              loading: _vm.loading,
                                              disabled: _vm.loading
                                            },
                                            on: { click: _vm.reintallService }
                                          },
                                          [
                                            _vm._v(
                                              "Reinstall\n                                    "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ]
                      ]
                    : [
                        _c("fy-vue-skeleton", {
                          attrs: {
                            animation: "fade",
                            rowOptions: { padding: "10px" },
                            childOptions: _vm.skeletonOptions
                          }
                        })
                      ],
                  _c("Help", {
                    attrs: {
                      mod: ["menu_tab_ftps"],
                      product: _vm.appInfo.product_name
                    }
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("DelFtp", { ref: "delftp", on: { confirm: _vm.delService } }),
      _c("Confirm", {
        ref: "ticket",
        attrs: { maxWidth: "500px" },
        on: { confirm: _vm.openTicket },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("span", [
                  _vm._v("\n                Open New Ticket\n            ")
                ])
              ]
            }
          },
          {
            key: "content",
            fn: function(ref) {
              var options = ref.options
              return [
                _c("p", [
                  _vm._v(
                    "\n                Please confirm and click Send in the next ticket panel. Our support staff will respond to you in 1-2\n                hours.\n            "
                  )
                ])
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }