var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", "no-click-animation": "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Stop Service")]),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                [
                  !_vm.isTransfer
                    ? _c("v-flex", { attrs: { md12: "" } }, [
                        _vm._v(
                          "\n                    Are you sure you want to stop the FTPS Server?\n                "
                        )
                      ])
                    : _c("v-flex", { attrs: { md12: "" } }, [
                        _vm._v(
                          "\n                    Data is being transferred. Are you sure you want to stop the FTPS service?\n                "
                        )
                      ])
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  staticClass: "ml-3 text-none",
                  attrs: { small: "", color: "blue darken-1", flat: "" },
                  on: { click: _vm.close }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "text-none",
                  attrs: { small: "", color: "primary", loading: _vm.loading },
                  on: { click: _vm.ok }
                },
                [_vm._v("Confirm")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }