var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "property-table", attrs: { border: "0" } },
    [
      _vm._l(_vm.lists, function(list, index) {
        return [
          _c(
            "tr",
            { key: index, staticClass: "tr-t" },
            _vm._l(list[0], function(val, i) {
              return _c("td", {
                key: i,
                staticClass: "font-weight-bold fs-4",
                attrs: { width: 100 / _vm.cols + "%" },
                domProps: { innerHTML: _vm._s(val) }
              })
            }),
            0
          ),
          _c(
            "tr",
            { key: index, staticClass: "tr-b" },
            _vm._l(list[1], function(val, i) {
              return _c("td", {
                key: i,
                staticClass: "fs-4",
                domProps: { innerHTML: _vm._s(val) }
              })
            }),
            0
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }